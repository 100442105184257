import { useContext, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { createUseStyles } from 'react-jss'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import Overlay from '@/components/Overlay'
import Spinner from '@/components/Spinner'
import StaticNoise from '@/components/StaticNoise'
import Nav from '@/components/Nav'
import SideRight from '@/components/SideRight'
import BottomNavigation from '@/components/BottomNavigation'
import SVGSprite from '@/components/SVGSprite'
import { Context as LoadingContext } from '@/context/loading'
import useMediaQuery from '@/hooks/useMediaQuery'
import usePreloadFont from '@/hooks/usePreloadFont'
import { config } from '@/constants'
import global from '@/global'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()
  const [mediaQueryKey] = useMediaQuery()
  const { setUniversalReady } = useContext(LoadingContext)

  usePreloadFont({
    cb: () => setUniversalReady(true),
  })


  const { headerNav, mainNav, extraNav } = useSelector((state) => {
    return ({
      extraNav: state.nav.extra_menu,
      headerNav: state.nav.header_menu,
      contactNav: state.nav.contact_menu,
      languageNav: state.nav.language_menu,
      mainNav: state.nav.main_menu,
      strings: state.options.strings,
    })
  }, shallowEqual)

  return (
    <div className={`${classes.root} content-root`}>
      <ConditionalWrapper
        condition={process.env.NODE_ENV === 'production'}
        wrapper={(child) => <GoogleReCaptchaProvider reCaptchaKey={config.RECAPTCHA_SITE_KEY}>{child}</GoogleReCaptchaProvider>}
      >
        <Loader />
        {mediaQueryKey > 1 && <Overlay />}
        <Spinner />
        <Nav
          mainNav={mainNav}
          extraNav={extraNav}
        />
        <SideRight />
        <BottomNavigation />
        <Header headerNav={headerNav} />
        <div className="wrapper">
          {children}
        </div>
        <SVGSprite />
      </ConditionalWrapper>
      <StaticNoise />
    </div>
  )
}

export default Layout
